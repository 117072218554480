import React, { Component } from 'react';
import { Col, Row } from 'antd';
import FormCard from "./FormCard";
import AddProduct from "./AddProduct";
import ProductList from "./ProductList";
import TotalComponent from './TotalComponent';
import ApplicantForm from "./forms/ApplicantForm";
import SendTo from "./forms/SendTo";
import SendButton from "./SendButton";
import SwitchIva from "./forms/SwitchIva";

export default class Main extends  Component{

    render()
    {
        return (
            <div className='container' >
                <Row gutter={24}>
                    <Col sm={{ span: 24}} lg={{span: 12}}>
                        <FormCard title={'Información Proveedor'} context={'provider'}/>
                    </Col>
                    <Col sm={{ span: 24}} lg={{span: 12}}>
                        <SendTo/>
                        <ApplicantForm/>
                    </Col>
                </Row>

                <Row gutter={24} className='margin-top'>
                    <Col md={{span: 24}} sm={{ span: 24}} lg={{span: 12}}><AddProduct/></Col>
                    <Col md={{ span: 24}} sm={{ span: 24}} lg={{span: 12}}>
                        <ProductList/>
                        <SwitchIva/>
                        <TotalComponent />
                        <SendButton/>
                    </Col>
                </Row>
            </div>
        )
    }
}