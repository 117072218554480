import React,{ Component } from 'react';
import { Table, Divider, Popconfirm, Modal } from 'antd';
import ProductForm from "./forms/ProductForm";
import { observer, inject } from 'mobx-react';
import { formatNumber } from "../functions/util";

const { Column } = Table;

@inject('RootStore')
@observer
class ProductList extends Component{

    constructor( props )
    {
        super( props );
        this.store = props.RootStore;
        this.state = {
            modalVisible: false,
            selected: {}
        }
    }

    deleteProduct = ( product ) =>
    {
        this.store.deleteProduct( product );
    };

    handleOnEdit = () =>
    {
        if ( this.store.editProduct() )
        {
            this.handleModal( false );
        }
    };

    handleModal = ( value ) =>
    {
        this.setState( { modalVisible: value } );
    };

    editItem = ( value, product ) =>
    {
        this.handleModal( value );
        this.store.edit                 = product;
    };

    render()
    {
        const products = this.store.products.toJS();

        return(
            <div>
                <Table dataSource={ products } className='table table-fix' pagination={{ pageSize: 6 }}>
                    <Column
                        title='#'
                        dataIndex='key'
                        key='key'
                        align='center'
                    />
                    <Column
                        title='Descripción'
                        dataIndex='name'
                        key='name'
                        render={ ( text, product ) => text}
                    />
                    <Column
                        title='Cantidad'
                        dataIndex='amount'
                        align='center'
                        key='amount'
                    />
                    <Column
                        title='Monto'
                        dataIndex='price'
                        align='center'
                        key='price'
                        render={ ( text, product ) => <span>{ formatNumber( product.price ) }</span>}
                    />
                    <Column
                        title='Categoria'
                        dataIndex='category'
                        key='category'
                        render={ ( text, product ) => <span>{ this.store.getCategoryName( product.category ) }</span>}
                    />
                    <Column
                        title='Acción'
                        key='action'
                        align='center'
                        render={ ( text, product) => (
                            <span>
                                <span onClick={ () => { this.editItem( true, product ); } }>
                                    <i className="fas fa-1x fa-edit icon icon-edit"/>
                                </span>
                                <Divider type='vertical'/>
                                <span>
                                    <Popconfirm placement="topLeft"
                                                title='¿Está seguro de querer eliminar el producto?'
                                                okText='Eliminar'
                                                cancelText='Cancelar'
                                                onConfirm={ () => this.deleteProduct(product) }>
                                        <i className="fas fa-1x fa-times-circle icon icon-delete" />
                                    </Popconfirm>
                                </span>
                            </span>
                        )}
                    />
                </Table>

                <Modal
                    title='Editar Producto'
                    visible={ this.state.modalVisible }
                    onOk={ this.handleOnEdit }
                    onCancel={ () => this.handleModal( false ) }
                    okText='Editar'
                    cancelText='Cancelar'
                >
                    <ProductForm context='edit'/>
                </Modal>
            </div>
        )
    }
}


export default ProductList;