import { observable, get, set, action, toJS } from 'mobx';




class RootStore {

    @observable provider      = {
        id      : null,
        name    : '',
        rut     : '',
        email   : '',
        address : '',
        city    : '-',
    };
    @observable reciver = null;
    @observable applicant = {
        name    : '',
        email   : ''
    };
    @observable add     = {
        key     : null,
        name    : '',
        price   : '',
        amount  : '',
        category: 'Selecciona una categoria',
    };
    @observable edit     = {
        key     : null,
        name    : '',
        price   : '',
        amount  : '',
        category: null
    };
    @observable products            = [];
    @observable categories          = [];
    @observable IVA                 = true;
    @observable tax                 = 0;
    @observable comment             = '';
    @observable loading             = false;

    @set addProduct()
    {
        if ( this.validateProduct( this.add ) )
        {
            this.add.key            = this.products.length + 1;
            this.products.push( toJS( this.add ) );
            this.add                = this.clear();
            return true;
        }
    }

    @set editProduct( )
    {
        if ( this.validateProduct( this.edit ) )
        {
            const index             = this.products.indexOf( this.edit );
            this.products[ index ]  = this.edit;
            this.edit               = this.clear();
            return true;
        }
    }

    @action validateProduct( { name, price, amount, category} )
    {
        return name !== '' && price !== '' && amount !== '' && typeof ( category ) === 'number' && price !== '0' && amount !== '0';
    }

    @set deleteProduct( product )
    {
        this.products = this.products.filter( (p) => p !== product);
    }

    @get getInformation( )
    {
        let information = {
            subtotal: 0,
            tax: 0,
            total: 0,
        };

        this.products.forEach( ( product ) =>
        {
            information.subtotal += product.price * product.amount;
        });

        information.tax     = this.IVA ? ( this.tax * information.subtotal) : 0;
        information.total   = information.subtotal + information.tax;

        return information;
    }

    @get getForm()
    {
        return {
            provider    : toJS( this.provider ),
            reciver     : toJS( this.reciver ),
            applicant   : toJS( this.applicant ),
            products    : toJS( this.products ),
            comment     : this.comment,
            IVA         : this.IVA
        }
    }

    @action clear()
    {
        return {
            key     : null,
            name    : '',
            price   : '',
            amount  : '',
            category: 'Selecciona una categoria'
        };
    }


    @action validateForm()
    {
        if ( this.provider.rut === '' && this.provider.name === '')
            return 'Los campos rut y nombre son requerido en: Información del proveedor.';

        if ( ! this.reciver )
            return 'Seleccione el correo del receptor.';

        if ( ! this.applicant.name && ! this.applicant.email )
            return 'Los campos del solicitante son requeridos.';

        if ( this.products.length === 0)
            return 'Debe haber como minimo un producto.';
        return true;
    }

    @set clearForm()
    {
        this.add        = this.clear();
        this.edit       = this.clear();
        this.provider     = {
            id      : null,
            name    : '',
            rut     : '',
            email   : '',
            address : '',
            city    : '-',
        };

        this.applicant = {
            name    : '',
            email   : ''
        };

        this.comment    = '';

        this.products   = [];
    }

    @get getCategoryName( id )
    {
        return this.categories.find( c => c.id === id ).name;
    }
}


const rootStore = new RootStore();
export default rootStore;