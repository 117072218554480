import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Card, Checkbox } from 'antd';

@inject('RootStore')
@observer
class SwitchIva extends Component{

    constructor( props )
    {
        super( props );
        this.store = props.RootStore;
    }

    handleOnChange = ( { target: { checked } } ) =>
    {
        this.store.IVA = checked;
    };

    render()
    {
        return (
            <div className='mt-2 mb-2'>
                <Card>
                    <Checkbox
                        onChange={ this.handleOnChange }
                        checked={ this.store.IVA }
                        className='d-h4'
                    >
                        IVA
                    </Checkbox>
                </Card>
            </div>
        )
    }
}

export default SwitchIva;