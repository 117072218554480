import React, { Component } from 'react';
import { Card } from 'antd';
import ProviderIformation from "./forms/ProviderIformation";


export default class FormCard extends Component{


    render()
    {
        const { title, context } = this.props;
        return (
            <Card title={title}>
                <ProviderIformation context={ context }/>
            </Card>
        )
    }
}