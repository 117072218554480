import React, { Component } from 'react';
import { Card, Divider } from 'antd';
import { observer, inject } from 'mobx-react';
import { formatNumber } from "../functions/util";
import axios from 'axios';
import { SERVER_URL } from "../config";

@inject('RootStore')
@observer
class TotalComponent extends Component{


    componentDidMount()
    {
        axios.get(`${SERVER_URL}/api/tax`).then(
            ( { data } ) =>
            {
                const { RootStore } = this.props;
                RootStore.tax       = data.tax;
            }
        );
    }

    render() {
        const { RootStore } = this.props;
        const information   = RootStore.getInformation();

        return (
            <div className='margin-top'>
                <Card>
                    <h4>Subtotal: { formatNumber( information.subtotal ) }</h4>
                    <h4 className={ ! RootStore.IVA ? 'disabled-text' : '' }>Impuesto: { formatNumber( information.tax ) }</h4>
                    <Divider/>
                    <h4>Total: { formatNumber( information.total ) }</h4>
                </Card>
            </div>
        );
    }
}

export default TotalComponent;