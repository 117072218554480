import React, { Component } from 'react';
import Main from './components/Main';
import { Spin } from 'antd';
import { observer, inject } from 'mobx-react';
import './App.css';

@inject('RootStore')
@observer
class App extends Component {
  render() {
      const { RootStore } = this.props;
    return (
      <div>
        <Spin spinning={ RootStore.loading } tip="Enviando información">
            <div>
                <Main/>
            </div>
        </Spin>
      </div>
    );
  }
}

export default App;
