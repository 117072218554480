import React, { Component } from 'react';
import { Form, Button, Col, Input, Row, message, Select } from "antd";
import NumericInput from "./NumericInput";
import { observer, inject } from 'mobx-react';
import axios from 'axios';
import { SERVER_URL } from "../../config";


@inject('RootStore')
@observer
class ProductForm extends Component {

    constructor(props)
    {
        super(props);
        this.store = props.RootStore;
        this.state = {
            context     : props.context,
        }
    }

    componentDidMount()
    {
        if ( this.store.categories.length === 0 )
        {
            axios.get(`${SERVER_URL}/api/categories`).then(
                ( { data } ) =>
                {
                    this.store.categories = data;
                }
            );
        }
    }

    handleInput = ( { target } ) =>
    {
        this.store[ this.state.context ].name = target.value;
    };

    handleSubmit = (e) =>
    {
        e.preventDefault();
        if ( !this.store.addProduct() )
            message.error( 'Los campos con * son requeridos' );
    };

    handleOnChange = ( value ) =>
    {
        this.store[ this.state.context ].category = value;
    };

    render()
    {
        return(
            <Form onSubmit={ this.handleSubmit }>
                <Form.Item>
                    <div className='label'>Descripción <span>*</span></div>
                    <Input
                        type='text'
                        value={ this.store[ this.state.context ].name }
                        placeholder='Descripción del producto'
                        onChange={ this.handleInput }
                    />
                </Form.Item>
                <Form.Item>
                    <Row gutter={18}>
                        <Col span={12}>
                            <div className='label'>Cantidad <span>*</span></div>
                            <NumericInput
                                placeholder='Cantidad del producto'
                                value={ this.store[ this.state.context ].amount }
                                onChange={ ( amount ) => this.store[ this.state.context ].amount = amount }
                            />
                        </Col>
                        <Col span={12}>
                            <div className='label'>Monto <span>*</span></div>
                            <NumericInput
                                placeholder='Precio por unidad'
                                value={ this.store[ this.state.context ].price }
                                onChange={ ( price ) => this.store[ this.state.context ].price = price }
                            />
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item>
                    <div className='label'>Tipo de gasto <span>*</span></div>
                    <Select value={ this.store[ this.state.context ].category } defaultValue='Selecciona una categoria' onChange={ this.handleOnChange }>
                        {
                            this.store.categories.map( ( category ) => (
                                <Select.Option
                                    key={ category.id }
                                    value={ category.id }>
                                    { category.name }
                                </Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>

                {
                    this.state.context === 'add' && (
                        <Form.Item>
                            <Button type="primary" htmlType="submit">Agregar a la lista</Button>
                        </Form.Item>
                    )
                }
            </Form>
        )
    }
}


export default ProductForm;