import React, { Component } from 'react';
import { Divider, Input } from 'antd';
import  { observer, inject } from 'mobx-react';

@inject('RootStore')
@observer
class AdditionalComment extends Component {

    RootStore = this.props.RootStore;

    handleOnChange = ( { target: { value } } ) =>
    {
        this.RootStore.comment = value;
    };

    render()
    {
        return(
            <div className='margin-top'>
                <Divider orientation='left'>Comentarios o instrucciones adicionales</Divider>
                <Input.TextArea
                    value={ this.RootStore.comment }
                    name='comment'
                    autosize={{ minRows: 5, maxRows: 6 }}
                    onChange={ this.handleOnChange }>

                </Input.TextArea>
            </div>
        )
    }
}


export default AdditionalComment;