import React, { Component } from 'react';
import { Card } from 'antd';
import AdditionalComment from "./forms/AdditionalComment";
import ProductForm from "./forms/ProductForm";



class AddProduct extends Component{

    render()
    {
        return(
            <Card title='Añadir Producto'>
                <ProductForm context='add'/>
                <AdditionalComment />
            </Card>
        )
    }
}


export default AddProduct;