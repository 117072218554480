import React, { Component } from 'react';
import { Button, message, notification } from 'antd'
import { observer, inject } from 'mobx-react';
import { SERVER_URL } from './../config';
import axios from 'axios';

@inject('RootStore')
@observer
class SendButton extends Component {

    order_id = null;

    argsNotification  = {
        key         : `open${Date.now()}`,
        message     : 'Información enviada.',
        description : 'Toda la información fue enviada al correo.',
        duration    : 0,
       /* btn         : (
            <Button type="primary" size="small" onClick={ () => window.open( `${ SERVER_URL }/order/${ this.order_id }` )}>
                Ver PDF
            </Button>
        )*/
    };

    handleSendData = () =>
    {
        const { RootStore } = this.props;
        const validation    = RootStore.validateForm();

        if ( validation === true )
        {
            window.scroll(0,0);
            RootStore.loading = true;
            axios.post( `${ SERVER_URL }/api/order`, RootStore.getForm() )
                .then( ( { data: { order_id, status }} ) =>
                {
                    RootStore.loading   = false;
                    this.order_id       = order_id;
                    notification.open( this.argsNotification );
                    RootStore.clearForm();
                });
        }else
            message.error( validation );
    };

    render()
    {
        return(
            <div className='margin-top'>
                <Button
                    onClick={ this.handleSendData }
                    type={'primary'}
                    htmlType='submit'
                    block
                >
                    Enviar
                </Button>
            </div>
        )
    }
}

export default SendButton;