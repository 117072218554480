import React, { Component } from 'react';
import { Card, Form, Input } from 'antd';
import { observer, inject } from 'mobx-react';


@inject('RootStore')
@observer
class ApplicantForm extends Component{

    constructor(props) {
        super(props);
        this.store = props.RootStore;
    }

    handleInput = ( { target: { value, name } } ) =>
    {
        this.store.applicant[ name ] = value;
    };

    render()
    {
        return (
            <div className='margin-top'>
                <Card title='Información Solicitante'>
                    <Form>
                        <Form.Item>
                            <Input
                                name='name'
                                value={ this.store.applicant.name }
                                size='large'
                                type='text'
                                prefix={<i className="fas fa-address-card" /> }
                                placeholder='Nombre del solicitante'
                                onChange={ this.handleInput }
                            />
                        </Form.Item>
                        <Form.Item>
                            <Input
                                name='email'
                                value={ this.store.applicant.email }
                                type='text'
                                prefix={ <i className="far fa-envelope" /> }
                                placeholder='Correo Electronico'
                                size='large'
                                onChange={ this.handleInput }
                            />
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        )
    }
}

export default ApplicantForm;