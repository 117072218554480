import React, { Component } from 'react';
import { Card, Select } from 'antd';
import axios from 'axios';
import { SERVER_URL } from "../../config";
import { observer, inject } from 'mobx-react';
const { Option } = Select;


@inject('RootStore')
@observer
class SendTo extends Component{

    constructor()
    {
        super();
        this.state = {
            recivers: [],
            selected: 'Selecciona a quien recibirá el correo',
        }
    }

    componentDidMount()
    {
        axios.get(`${ SERVER_URL }/api/recivers`).then(
            ( { data } ) =>
            {
                this.setState( { recivers: data } );
            }
        );
    }

    handleOnChange  = ( value ) =>
    {
        const { RootStore } = this.props;
        this.setState( { selected: value } );
        RootStore.reciver   = this.state.recivers.find( re => re.id === value );
    };

    render()
    {
        return (
            <Card title='Enviar a: '>
                <Select
                    onChange={ this.handleOnChange }
                    value={ this.state.selected }
                    size='large'
                    id='send-to'>
                    {
                        this.state.recivers.map( ( user) => (
                            <Option value={ user.id } key={ user.id }>{ user.name } - { user.email }</Option>
                        ))
                    }
                </Select>
            </Card>
        )
    }
}


export default SendTo;