import React, { Component } from 'react';
import {Col, Row, Input, Form, AutoComplete} from "antd";
import  { observer, inject } from 'mobx-react';
import { SERVER_URL } from "../../config";
import axios from 'axios';

@inject('RootStore')
@observer
class ProviderIformation extends Component{

    constructor(props)
    {
        super(props);
        this.store = props.RootStore;
        this.state = {
            context     : props.context,
            providers   : []
        }
    }

    handleInput = ( { target:{ name, value} } ) =>
    {
        this.store.provider[ name ] = value;
    };

    handleRutInput = ( value ) =>
    {
        /*const re = /\d\d[.]\d\d\d[.]\d\d\d[-][(0-9)|A-Z]/;*/
        axios.get( `${ SERVER_URL }/api/providers/${value}` ).then(
            ( { data } ) =>
            {
                this.setState({ providers: data });
            }
        );
        this.store.provider.rut = value;
    };

    handleOnSelect = ( value ) =>
    {
        const findProv      = value.split(' ')[0];
        let prov            = [];
        if ( value.length !== 0 )
             prov = this.state.providers.find( ( p ) => p.rut === findProv );

        this.store.provider = prov;
    };

    render()
    {
        const ruts = this.state.providers.map( ( p ) => `${ p.rut } - ${ p.name }`);
        return (
            <Form>

                <Form.Item>
                    <AutoComplete
                        name='rut'
                        type='text'
                        dataSource={ ruts }
                        value={ this.store.provider.rut }
                        prefix={<i className="fas fa-sort-numeric-up" /> }
                        placeholder='Busqueda por nombre o Rut'
                        size='large'
                        onSelect={ this.handleOnSelect }
                        onSearch={ this.handleRutInput }
                    />
                </Form.Item>

                <Form.Item>
                    <Input
                        name='email'
                        type='text'
                        value={ this.store.provider.email }
                        prefix={ <i className="fas fa-envelope" /> }
                        placeholder='Correo Electronico'
                        size='large'
                        onChange={ this.handleInput }
                    />
                </Form.Item>

                <Form.Item>
                    <Input
                        name='name'
                        type='text'
                        value={ this.store.provider[ 'name' ] }
                        prefix={ <i className="fas fa-building icon-color" />}
                        placeholder='Nombre de la empresa'
                        size="large"
                        onChange={ this.handleInput }
                    />
                </Form.Item>

                <Form.Item>
                    <Row gutter={18}>
                        <Col span={24}>
                            <Input
                                name='address'
                                value={ this.store.provider[ 'address' ] }
                                type='text'
                                prefix={ <i className="fas fa-map-marker-alt icon-color" />}
                                placeholder='Dirección'
                                size="large"
                                onChange={ this.handleInput }
                            />
                        </Col>
                        {
                            /*                        <Col span={12}>
                            <Input
                                name='city'
                                value={ this.store.provider[ 'city' ] }
                                type='text'
                                prefix={<i className="fas fa-globe"/>}
                                placeholder='Ciudad, Estado'
                                size="large"
                                onChange={ this.handleInput }
                            />
                        </Col>*/
                        }
                    </Row>
                </Form.Item>
            </Form>
        )
    }
}

export default ProviderIformation;
